import React from "react";
import { YafBannerImage } from "../../core/constants/Images";

const TabCard = (props) => {
  const { tabCardContentData } = props;
  console.log("tabCardContentData", tabCardContentData);
  return (
    <div className="md:flex gap-6">
      {/* <div className="max-w-md mx-auto shadow-lg rounded-lg test-background bg-gray-800 dark:border-gray-700">
                <a href="#">
                    <img className="rounded-t-lg" src={YafBannerImage} alt="" />
                </a>
                <div className="p-5">
                    <a href="#">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-primary">Noteworthy technology acquisitions 2021</h5>
                    </a>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
                </div>
            </div> */}
      <div className="max-w-3xl mx-auto mt-8 md:mt-0 border border-gray-200 rounded-lg shadow-lg bg-gray-800">
        <img
          className={
            tabCardContentData.id == 1
              ? "rounded-t-lg object-contain py-10  w-full h-48 md:h-60"
              : tabCardContentData.id == 4 || tabCardContentData.id == 5
              ? "rounded-t-lg object-contain  w-full h-72 md:h-96"
              : tabCardContentData.id == 3
              ? "rounded-t-lg object-contain w-full h-48 md:h-80"
              : "rounded-t-lg object-cover  w-full h-48 md:h-96"
          }
          src={tabCardContentData.featuredImage}
          alt="year-wise-image"
        />
        <div className="p-5">
          <h5 className="mb-2 text-lg md:text-2xl font-bold tracking-wide text-white">
            {tabCardContentData.title}
          </h5>
          <p className="mb-3 text-base font-normal text-gray-300">
            {tabCardContentData.subTitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TabCard;
