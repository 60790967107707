import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./sidebar.css";
import {
  Home,
  JoinUsSidebar,
  MapSidebar,
  Newspaper,
  Revenue,
  StepsSidebar,
  TimelineSidebar,
  YafLogo,
  YafNewLogo,
  YouthAllianceFundLogo,
} from "../../core/constants/Images";
const Sidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const handleToggle = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const handleClose = () => {
    setSidebarVisible(false);
  };

  return (
    <div className="">
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        id="myHeader"
        className="sidebar-toggle"
        onClick={handleToggle}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          data-slot="icon"
          class="w-9 text-primary h-10"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5"
          />
        </svg>
      </motion.button>
      <aside
        className={`sidebar ${
          sidebarVisible ? "show-sidebar shadow-2xl drop-shadow-2xl" : ""
        }`}
      >
        <div className="sidebar-header">
          <img src="logo.svg" className="logo" alt="" />
          <button className="close-btn" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              data-slot="icon"
              class="w-10 h-10"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
        <hr />
        <ul className="links">
          <li onClick={handleClose}>
            <div className="flex p-4">
              <img
                className="h-7 mx-2 md:h-10 md:w-10  opacity-75"
                src={Home}
                alt="yaflogo"
              />
              <a className="text-primary ml-2" href="#home">
                home
              </a>
            </div>
          </li>
          <li onClick={handleClose}>
            <div className="flex p-4">
              <img
                className="h-7 mx-2 md:h-10 md:w-10  opacity-75"
                src={StepsSidebar}
                alt="yaflogo"
              />
              <a className="text-primary ml-2" href="#steps">
                steps
              </a>
            </div>
          </li>
          <li onClick={handleClose}>
            <div className="flex p-4">
              <img
                className="h-7 mx-2 md:h-10 md:w-10  opacity-75"
                src={Newspaper}
                alt="yaflogo"
              />
              <a className="text-primary ml-2" href="#updates">
                recent updates & news
              </a>
            </div>
          </li>
          <li onClick={handleClose}>
            <div className="flex p-4">
              <img
                className="h-7 mx-2 md:h-10 md:w-10  opacity-75"
                src={TimelineSidebar}
                alt="yaflogo"
              />
              <a className="text-primary ml-2" href="#timeline">
                timeline
              </a>
            </div>
          </li>
          <li onClick={handleClose}>
            <div className="flex p-4">
              <img
                className="h-7 mx-2 md:h-10 md:w-10  opacity-75"
                src={Revenue}
                alt="yaflogo"
              />
              <a className="text-primary ml-2" href="#chart">
                year wise growth
              </a>
            </div>
          </li>
          <li onClick={handleClose}>
            <div className="flex p-4">
              <img
                className="h-7 mx-2 md:h-10 md:w-10  opacity-75"
                src={MapSidebar}
                alt="yaflogo"
              />
              <a className="text-primary ml-2" href="#map">
                yaf map
              </a>
            </div>
          </li>
          <li onClick={handleClose}>
            <div className="flex p-4">
              <img
                className="h-7 mx-2 md:h-10 md:w-10  opacity-75"
                src={JoinUsSidebar}
                alt="yaflogo"
              />
              <a className="text-primary ml-2" href="#join-us">
                Join US
              </a>
            </div>
          </li>
        </ul>
        <div className="flex justify-end p-8">
          <img className="w-11 md:w-12" src={YafNewLogo} alt="yaf-logo" />
        </div>

        <ul className="social-icons">
          <li>
            <a href="https://www.twitter.com">
              <i className="fab fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com">
              <i className="fab fa-behance"></i>
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com">
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com">
              <i className="fab fa-sketch"></i>
            </a>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
