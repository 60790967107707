import React, { useEffect, useState } from "react";
import {
  Instagram,
  YafGroupLogo,
  YafNewLogo,
  YouthAllianceFundLogo,
  YouthAllianceFundLogoWithTitle,
  Youtube,
} from "../../core/constants/Images";
import { motion } from "framer-motion";
import { UpOutlined } from "@ant-design/icons";

const Footer = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const handleScrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop > 2000;
    setShowScrollToTop(scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="border-y-2 py-6">
      <div className="max-w-5xl mx-auto">
        <div className="md:flex gap-4 justify-between items-center">
          <div className="md:w-1/2">
            <div className="flex">
              <img
                className="w-8 md:w-12 md:hidden"
                src={YafNewLogo}
                alt="yaf-logo"
              />
              <img
                className="w-60 md:w-80"
                src={YouthAllianceFundLogoWithTitle}
                alt="yaf-logo"
              />
            </div>
            {/* <img className="w-60 md:w-80" src={YafGroupLogo} alt="YafGroupLogo" /> */}
          </div>
          <a
            className="z-10 md:hidden fixed bottom-10 right-5 md:right-16"
            href="https://app.youthalliancefund.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-blue-700 hover:bg-blue-600 z-10 font-semibold text-white rounded-full p-2.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>
            </button>
          </a>
          {showScrollToTop && (
            <a
              href="#"
              className="fixed bottom-24 md:bottom-10 right-5 md:right-16"
              onClick={handleScrollToTop}
            >
              <UpOutlined className="scroll-to-top-icon rounded-full" />
            </a>
          )}

          <div className="flex p-2 justify-between items-center">
            <p className="mx-2">Follow us on:</p>
            <div className="flex justify-end">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <a
                  href="https://youtube.com/@YouthAllianceFund?si=-eUGZOPMI_n9el4c"
                  target="_blank"
                >
                  <img
                    className="w-10 md:w-16 mx-2"
                    src={Youtube}
                    alt="YafGroupLogo"
                  />
                </a>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <a
                  href="https://www.instagram.com/youth.alliance.fund?utm_source=qr&igsh=dnZoY3JtYWhlcHgy"
                  target="_blank"
                >
                  <img
                    className="w-8 md:w-11"
                    src={Instagram}
                    alt="YafGroupLogo"
                  />
                </a>
              </motion.button>
            </div>
          </div>
        </div>
        <div className="px-3">
          &#169; {new Date().getFullYear()} Youth Alliance Fund
        </div>
      </div>
    </div>
  );
};

export default Footer;
