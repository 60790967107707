import React from "react";
import { Chart } from "react-google-charts";

export const dataOne = [
  ["Task", "Loan per year"],
  ["2020-21", 20000],
  ["2021-22", 194000],
  ["2022-23", 335000],
  ["2023-24", 130000],
];
export const dataTwo = [
  ["Task", "Loan per year"],
  ["2020", 18900],
  ["2021", 44100],
  ["2022", 84600],
  ["2023", 72000],
  ["2024", 6000],
];

export const optionsOne = {
  title: "Loan Provided in Financial Year",
};
export const optionsTwo = {
  title: "Year Wise Saving Amount Received",
};

export function ChartsTwo() {
  return (
    <div id="chart" className="max-w-7xl mx-auto overflow-hidden">
      <div className="flex justify-center">
        <h2 className="font-bold tracking-wide text-2xl md:text-3xl md:mb-6">
          Our Progress
          <div class="animation h-1 mt-1"></div>
        </h2>
      </div>
      <div className="px-4 md:px-0 md:flex rouned-md">
        <div className="bg-primary-100 p-2 md:p-2 rounded-lg md:px-2 md:mx-4 w-full my-6">
          <Chart
            chartType="PieChart"
            data={dataOne}
            options={optionsOne}
            width={"100%"}
            height={"400px"}
          />
        </div>
        <div className="bg-primary-100 p-2 md:p-2 rounded-lg md:px-2 md:mx-4 w-full  my-6">
          <Chart
            chartType="PieChart"
            data={dataTwo}
            options={optionsTwo}
            width={"100%"}
            height={"400px"}
          />
        </div>
      </div>
    </div>
  );
}
