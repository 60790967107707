import React from "react";
const YafMaps = () => {
  return (
    <>
      <div className="flex justify-center pt-5 md:pt-12">
        <h2 className="font-bold tracking-wide text-2xl md:text-3xl md:mb-6">
          Yaf Map
          <div class="animation h-1 mt-1"></div>
        </h2>
      </div>
      <div id="map" className="flex justify-center py-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.0999023609625!2d75.07929627484691!3d13.272977787070744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbb511cd3568495%3A0xa03842685d108f51!2sYouth%20Alliance%20Fund!5e1!3m2!1sen!2sin!4v1704607548575!5m2!1sen!2sin"
          width="800"
          height="450"
          className="border outline outline-2 outline-offset-2 rounded-md border-primary"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default YafMaps;
