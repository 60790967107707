import React from "react";

const StepsSlider = (props) => {
  const { stepsData } = props;
  return (
    <>
      <div className="flex flex-col justify-start items-start gap-2 mb-6">
        <div className="flex items-center">
          <img
            className="h-12 w-12"
            src={stepsData.step_logo}
            alt="StepOneLogo"
          />
          {stepsData.step_logo_dot ? (
            <img
              className="h-6 w-80 mt-3"
              src={stepsData.step_logo_dot}
              alt="StepOneImage"
            />
          ) : (
            ""
          )}
        </div>
        <p className="font-bold mb-1">{stepsData.steps_title}</p>
        <p className="text-gray-500">{stepsData.steps_sub_title}</p>
      </div>
    </>
  );
};

export default StepsSlider;
