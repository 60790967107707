import React from "react";
import { MailIcon, PhoneIcon } from "../../core/constants/Images";

const ForMoreInformation = () => {
  return (
    <div className="my-4">
      <div className="flex justify-center pt-5 md:pt-12">
        <h2 className="font-bold tracking-wide text-2xl md:text-3xl md:mb-6">
          For More Information
          <div class="animation h-1 mt-1"></div>
        </h2>
      </div>
      <div className="md:px-4 py-2">
        <p className="text-lg font-bold flex gap-2 my-5">
          <img className="h-5 md:h-6" src={MailIcon} alt="phone-icon" />
          <span className="text-gray-500 text-sm md:text-lg">
            Email us at :
          </span>
          <a
            className="text-blue-50 text-sm md:text-lg"
            href="mailto:youthalliancefund@gmail.com"
          >
            youthalliancefund@gmail.com
          </a>
        </p>
        <hr />
        <p className="text-lg font-bold flex gap-2 my-5">
          <img className="h-5 md:h-6" src={MailIcon} alt="phone-icon" />
          <span className="text-gray-500 text-sm md:text-lg">
            Contact Our Developer Team at :
          </span>
          <a
            className="text-blue-50 text-sm md:text-lg"
            href="mailto:developer.youthalliancefund@gmail.com"
          >
            developer.youthalliancefund@gmail.com
          </a>
        </p>
        <hr />
        {/* mobile contact */}
        <p className="text-lg font-bold flex gap-2 my-5">
          <img className="h-5 md:h-6" src={PhoneIcon} alt="phone-icon" />
          <span className="text-gray-500 text-sm md:text-lg">
            Contact Us :{" "}
          </span>
          <a className="text-blue-50 text-sm md:text-lg" href="tel:9844286170">
            98 442 86 170{" "}
          </a>
          <span className="text-gray-800"> / </span>
          <a className="text-blue-50 text-sm md:text-lg" href="tel: 9686146334">
            {" "}
            96 861 46 334
          </a>
        </p>
      </div>
    </div>
  );
};

export default ForMoreInformation;
