import React from "react";
import { JoinUsImage } from "../../core/constants/Images";
import { motion } from "framer-motion";

const JoinUs = () => {
  return (
    <div className="py-6">
      {/* ready to join section start */}
      <div
        id="join-us"
        className="shadow-md card-background w-full p-6 flex justify-center items-center rounded-lg"
      >
        <div className="max-w-sm">
          <p className="font-semibold text-xl md:text-2xl mb-6">
            Ready to get started? Join thousands of others today.
          </p>
          <a
            href="https://app.youthalliancefund.in/application-form"
            target="_blank"
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="p-2 px-8 text-md md:text-xl bg-blue-600 hover:bg-blue-500 text-white rounded-full"
            >
              Join Us
            </motion.button>
          </a>
        </div>
        <div className="">
          <img className="w-72" src={JoinUsImage} alt="join-us-image" />
        </div>
      </div>
      {/* ready to join section end */}
    </div>
  );
};

export default JoinUs;
