import React from "react";
import {
  YafBannerImage,
  YafLogo,
  YouthAllianceFundLogoWithTitle,
} from "../../core/constants/Images";
import Steps from "../steps-section/Steps";
import OurAchievement from "../our-achievements/OurAchievement";
import Members from "../members/Members";
import JoinUs from "../join-us/JoinUs";
import Timeline from "../timeline/Timeline";
import Sidebar from "../sidebar/Sidebar";
import Charts from "../charts/Charts";
import YafHeader from "./YafHeader";
import Footer from "../yaf-footer/Footer";
import YafMaps from "../yaf-maps/Maps";
import AnimatedTabs from "../animated-tabs/AnimatedTabs";
import { motion } from "framer-motion";
import { ChartsTwo } from "../charts/ChartsTwo";
import ForMoreInformation from "../for-more-info/ForMoreInformation";

const YafBanner = () => {
  return (
    <div className="overflow-hidden">
      <Sidebar />
      <div id="home" className="relative flex justify-center">
        <img
          className="w-full banner-image md:h-full"
          src={YafBannerImage}
          alt="yaf-banner"
        />
        <YafHeader />
        {/* yaf title */}
        <div className="absolute top-0 w-full h-full flex flex-col items-center justify-center">
          <div className="text-center">
            <p className="youth-title md:text-5xl text-2xl capitalize text-primary font-extrabold">
              Youth Alliance Fund
            </p>
          </div>
          <a
            href="https://app.youthalliancefund.in/application-form"
            target="_blank"
          >
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="font-bold text-base md:text-lg bg-white transition ease-in duration-300 hover:bg-primary hover:text-white rounded-full py-2 md:py-3 shadow-lg text-primary-500 px-10 md:px-12 mt-4 md:mt-8 tracking-wide"
            >
              Start Saving
            </motion.button>
          </a>
        </div>
      </div>
      <div className="max-w-5xl mx-auto p-4">
        <Steps />
        <div className="pt-12 md:pt-8">
          <OurAchievement />
        </div>

        <div className="pt-10 py-4 md:py-12 my-8 md:my-8">
          <AnimatedTabs />
          {/* <Timeline /> */}
        </div>
      </div>
      {/* <Charts /> */}
      <ChartsTwo />
      <div className="max-w-5xl mx-auto p-4">
        {/* <Members /> */}
        <YafMaps />
        <ForMoreInformation />
        <JoinUs />
      </div>
      <div className="p-4">
        <Footer />
      </div>
    </div>
  );
};

export default YafBanner;
