import './App.css';
import YafBanner from './components/yaf-banner/YafBanner';

function App() {
  return (
    <div>
      <YafBanner/>
    </div>
  );
}

export default App;
