import React from "react";
import {
  YafLogo,
  YafNewLogo,
  YouthAllianceFundLogo,
} from "../../core/constants/Images";

const GallerySliderCard = (props) => {
  const { imageData, isFirstCard } = props;
  return (
    <>
      <div className="md:grid grid-cols-2 gap-4 md:gap-6 mx-2 md:mx-4">
        <div className="w-full shadow-md drop-shadow-md image-card-container">
          <div className="relative h-full border-4 border-primary-200 p-1 rounded-lg">
            {imageData.featuredImage ? (
              <img
                className="rounded-lg object-cover relative w-full h-72 md:h-96"
                src={imageData.featuredImage}
                alt="member-image"
              />
            ) : (
              imageData.launchVideo
            )}
          </div>
        </div>
        <div className="mt-8 h-96 recent-update-content-card-size md:h-full shadow-md md:mt-0 p-4 md:p-6 card-background rounded-lg flex flex-col justify-between">
          <div className="">
            {isFirstCard && (
              <>
                <p className="text-xl font-bold mb-2 text-black">
                  Dear members,
                </p>
              </>
            )}
            <p className="text-lg leading-relaxed md:leading-loose text-gray-600 tracking-wide font-medium">
              {imageData.title}
            </p>
          </div>
          <div className="flex md:mt-8 gap-2 justify-between items-start">
            <div className="">
              {isFirstCard && (
                <>
                  <p className="font-bold text-sm md:text-lg underline underline-offset-8">
                    Manjunath Gudigar
                  </p>
                  <p className="text-xs md:text-lg text-center bg-black w-28 mx-auto tracking-wide py-1 md:py-0 rounded-full text-white font-medium mt-3">
                    President
                  </p>
                </>
              )}
            </div>
            <div className="">
              {isFirstCard && (
                <>
                  <p className="font-bold text-sm md:text-lg underline underline-offset-8">
                    Ramanand Gudigar
                  </p>
                  <p className="text-xs md:text-lg text-center bg-black w-28 mx-auto tracking-wide py-1 md:py-0 rounded-full text-white font-medium mt-3">
                    Secretary
                  </p>
                </>
              )}
            </div>
            {!isFirstCard && (
              <>
                <img
                  className="w-8 md:w-12 md:hidden"
                  src={YafNewLogo}
                  alt="yaf-logo"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GallerySliderCard;
