import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  YafLogo,
  YafNewLogo,
  YouthAllianceFundLogo,
  YouthAllianceFundLogoWithTitle,
} from "../../core/constants/Images";

const YafHeader = () => {
  const logo = "";
  return (
    <>
      <div className="max-w-sm md:max-w-5xl mx-auto absolute w-full pt-6 px-1 md:px-4">
        <div className="flex justify-between items-center gap-4 py-2 px-6 bg-white rounded-full">
          <img
            className="invisible md:visible w-8 md:w-12"
            src={YafNewLogo}
            alt="yaf-logo"
          />

          <div className="flex items-center gap-4">
            <img
              className="w-48 md:w-80"
              src={YouthAllianceFundLogoWithTitle}
              alt="yaf-logo"
            />
          </div>
          <a
            className="z-10 hidden md:block"
            href="https://app.youthalliancefund.in"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-blue-700 hover:bg-blue-600 z-10 font-semibold text-white rounded-full py-1 px-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>
            </button>
          </a>
          <img
            className="w-8 md:w-12 md:hidden"
            src={YafNewLogo}
            alt="yaf-logo"
          />
        </div>
      </div>
    </>
  );
};

export default YafHeader;
