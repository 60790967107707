import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import StepsSlider from "./StepsSlider";
import StepsData from "./StepsData";
import "./Steps.css";
import {
  StepOneDot,
  StepOneLogo,
  StepThreeLogo,
  StepTwoDot,
  StepTwoLogo,
} from "../../core/constants/Images";

const Steps = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  var stepsSliderSettings = {
    dots: true,
    infinite: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          dots: true,
          slidesToShow: 1,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <div id="steps" className="steps-slider-parent py-8">
      <div className="">
        <p className="text-gray-500 mb-1">What to expect</p>
        <div className="max-w-sm">
          <h2 className="md:text-3xl mb-6 font-bold tracking-wide">
            Saving on Youth Alliance Fund takes just a few minutes
          </h2>
        </div>
      </div>

      {isSmallScreen ? (
        <Slider {...stepsSliderSettings}>
          {StepsData &&
            StepsData.map((item, index) => {
              return (
                <div className="pb-4" key={index}>
                  <StepsSlider stepsData={item} />
                </div>
              );
            })}
        </Slider>
      ) : (
        <>
          <div className="grid grid-cols-12 gap-8 w-full py-4">
            {StepsData &&
              StepsData.map((item, index) => {
                return (
                  <div className="col-span-4">
                    <div className="flex justify-start items-start mb-6">
                      <img
                        className="h-12 w-12"
                        src={item.step_logo}
                        alt="StepOneLogo"
                      />
                      {item.step_logo_dot ? (
                        <img
                          className="h-6 w-80 mt-3"
                          src={item.step_logo_dot}
                          alt="StepOneImage"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="font-bold text-gray-900 tracking-wide text-xl mb-1">
                      {item.steps_title}
                    </p>
                    <p className="text-gray-600 tracking-wide text-lg leading-relaxed">
                      {item.steps_sub_title}
                    </p>
                  </div>
                );
              })}
            {/* <div className="col-span-4">
                <div className="flex justify-start items-end mb-6">
                  <img
                    className="h-12 w-12"
                    src={StepTwoLogo}
                    alt="StepTwoLogo"
                  />
                  <img className="h-6 w-80" src={StepTwoDot} alt="StepTwoDot" />
                </div>
                <p className="font-medium mb-1">Tell your story</p>
                <p className="text-gray-500">
                  We'll guide you with tips along the way.
                </p>
              </div>
              <div className="col-span-4">
                <img
                  className="h-12 w-12"
                  src={StepThreeLogo}
                  alt="StepThreeLogo"
                />
                <div className="mt-6">
                  <p className="font-medium mb-1">
                    Share with friends and family
                  </p>
                  <p className="text-gray-500">
                    People out there want to help you.
                  </p>
                </div>
              </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default Steps;
