import {
  AnnualMeeting2024,
  AnnualMeetingMarch25,
  Chandan,
  ChiefGuest,
  Circulation,
  FeaturedImage,
  FinancialGrowth,
  MoneyGivingImage,
  PreAnnualMeet,
  YafLogo,
} from "../../core/constants/Images";

const tabCardContentData = {
  2020: {
    id: 1,
    date: "12 March 2023",
    title: "The Youth Alliance Fund (YAF) was established in December 2020.",
    featuredImage: YafLogo,
    subTitle: (
      <>
        <p>
          Since its establishment in December 2020, the Youth Alliance Fund
          (YAF) has charted a remarkable trajectory of growth and impact.
        </p>
        <p className="my-2">
          Dedicated to empowering youth, YAF actively seeks funding to support
          positive change, understanding the crucial role in financial support."
        </p>
      </>
    ),
  },
  2021: {
    id: 2,
    date: "12 March 2023",
    title: "Gradual Growth: Welcoming New Members to the Youth Alliance Fund",
    featuredImage: FinancialGrowth,
    subTitle: (
      <>
        <p>
          The Youth Alliance Fund (YAF) has steadily expanded its impact by
          welcoming new members into its fold.
        </p>
        <p className="my-2">
          Each addition to the Youth Alliance Fund represents a fresh
          perspective and a renewed dedication to the cause of empowering the
          youth.
        </p>
        <p>
          The continuous infusion of new members not only augments the strength
          of the organization but also gives strength to its initiatives.
        </p>
      </>
    ),
  },
  2022: {
    id: 3,
    date: "19 March 2023",
    title: "In the year 2022 , Youth Alliance Fund Elections took place.",
    featuredImage: Circulation,
    subTitle: (
      <>
        <p>
          In 2022, the Youth Alliance Fund witnessed a momentous occasion as
          Manjunath assumed the role of President, bringing with him a wealth of
          passion and dedication to the organization.
        </p>
        <p className="my-2">
          Secretary Ramanand Gudigar, elected alongside President Manjunath,
          complemented this vision with his insightful and forward-thinking
          approach. In his acceptance speech, Ramanand Gudigar spoke eloquently
          about fostering an environment of innovation and empowerment within
          the Youth Alliance Fund.
        </p>
        <p>
          Together, President Manjunath and Secretary Ramanand Gudigar expressed
          their firm belief in the potential of the youth to drive meaningful
          transformations in society.
        </p>
      </>
    ),
  },
  2023: {
    id: 4,
    date: "19 Sept 2023",
    title: "Youth Alliance Fund's Pre-Annual Meeting & App Launch event (2023)",
    featuredImage: PreAnnualMeet,
    subTitle: (
      <>
        <p>
          Embarked on a journey of empowerment at the Youth Alliance Fund's
          Pre-Annual Meeting & App Launch event in 2023.
        </p>
        <p className="mt-4">
          The Pre-Annual Meeting was inaugurated by our Chief Guest,
          <strong className="font-bold tracking-wider text-white">
            {" "}
            Mr. Umesh Gudigar
          </strong>
          , a Nuclear Inverter Manufacturer involved in service and distribution
          in Mumbai,
          <strong className="font-bold text-gray-800">
            {" "}
            on 19th September 2023.
          </strong>{" "}
        </p>
        <p className="">
          The groundbreaking mobile app was unveiled, symbolizing not just the
          launch of a tool but the initiation of a new era for youth
          empowerment. The event witnessed dynamic discussions, the forging of
          meaningful connections, and active participation in shaping the future
        </p>
        <p>
          Attendees engaged in explorative conversations, celebrated
          achievements, and laid the foundation for a year filled with growth,
          resilience, and boundless possibilities.{" "}
        </p>
      </>
    ),
  },
  2024: {
    id: 5,
    date: "19 Sept 2023",
    title: (
      <>
        <p>Annual meeting to be held on march 31st 2024.</p>
      </>
    ),
    featuredImage: AnnualMeetingMarch25,
    subTitle: (
      <>
        <p className="mt-4">
          <strong className="font-bold tracking-wider text-white">
            Dear YAF member,
          </strong>
        </p>
        <p className="mt-4">Youth Alliance Fund's incredible achievement....</p>

        <p className="">
          We're proud to share that we've provided a whopping 16% saving
          dividend for our members, fulfilling their needs and aspirations.
        </p>
        <p className="mt-4 text-white">-- Youth alliance fund</p>
      </>
    ),
  },
};
export default tabCardContentData;
