import {
    StepOneLogo,
    StepOneDot,
    StepTwoDot,
    StepTwoLogo,
    StepThreeLogo,
  } from "../../core/constants/Images";

const StepsData = [
  {
    id: 1,
    steps_title: "Click On Start Saving",
    steps_sub_title: "Kick things off with your name and location.",
    step_logo: StepOneLogo,
    step_logo_dot: StepTwoDot ,
  },
  {
    id: 2,
    steps_title: "Fill In Your YAF Form",
    steps_sub_title: "We'll guide you with tips along the way.",
    step_logo: StepTwoLogo,
    step_logo_dot: StepTwoDot,
  },
  {
    id: 3,
    steps_title: "Once Done Your Registration Is Completed",
    steps_sub_title: "People out there want to help you.",
    step_logo: StepThreeLogo,
  },

];
export default StepsData;
