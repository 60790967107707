import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import "./animatedTabs.css";
import tabCardContentData from "./tabCardData";
import TabCard from "./TabCard";

const AnimatedTabs = () => {
  const [activeTab, setActiveTab] = useState("2020");

  const tabs = [
    { id: "2020", label: "2020" },
    { id: "2021", label: "2021" },
    { id: "2022", label: "2022" },
    { id: "2023", label: "2023" },
    { id: "2024", label: "2024" },
  ];

  const tabContents = {
    2020: <TabCard tabCardContentData={tabCardContentData["2020"]} />,
    2021: <TabCard tabCardContentData={tabCardContentData["2021"]} />,
    2022: <TabCard tabCardContentData={tabCardContentData["2022"]} />,
    2023: <TabCard tabCardContentData={tabCardContentData["2023"]} />,
    2024: <TabCard tabCardContentData={tabCardContentData["2024"]} />,
  };
  const tabContentVariants = {
    initial: {
      y: 10,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: -10,
      opacity: 0,
    },
  };
  return (
    <>
      <div className="flex justify-center py-4">
        <h2 className="font-bold tracking-wide text-2xl md:text-3xl md:mb-6">
          Our Timeline
          <div class="animation h-1 mt-1"></div>
        </h2>
      </div>
      <div
        id="timeline"
        className="bg-primary-100 p-4 md:p-6 rounded-xl shadow-md drop-shadow-md space-x-1"
      >
        <div className="flex justify-center overflow-x-auto">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`${
                activeTab === tab.id ? "" : "hover:text-primary"
              } relative rounded-full px-6 tabs py-1.5 text-sm md:text-xl font-medium text-primary outline-sky-400 transition focus-visible:outline-2 `}
              style={{
                WebkitTapHighlightColor: "transparent",
              }}
            >
              {activeTab === tab.id && (
                <motion.span
                  layoutId="bubble"
                  className="absolute inset-0 z-10 mix-blend-overlay bg-primary"
                  style={{ borderRadius: 9999 }}
                  transition={{ type: "spring", bounce: 0.3, duration: 1.5 }}
                />
              )}
              {tab.label}
            </button>
          ))}
        </div>

        {/* <div className="mt-4 bg-black p-8 text-white">{tabContents[activeTab]}</div> */}
        <div className="tabContent">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              variants={tabContentVariants}
              initial="initial"
              animate="enter"
              exit="exit"
              transition={{
                duration: 0.3,
              }}
            >
              <div className="py-2 md:py-6">{tabContents[activeTab]}</div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default AnimatedTabs;
