import React from "react";
import { FeaturedImage, RightArrow } from "../../core/constants/Images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import galleryContentData from "./galleryData";
import GallerySliderCard from "./GallerySliderCard";
import "./Gallery.css";

const OurAchievement = () => {
  var gallerySliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          dots: true,
          slidesToShow: 1,
          swipeToSlide: true,
        },
      },
    ],
  };
  return (
    <div>
      <div id="updates">
        {/* <p className="text-gray-500 mb-1">Where you can help</p> */}
        <div className="flex justify-center">
          <h2 className="font-bold tracking-wide text-2xl md:text-3xl mb-5">
            Recent Updates
            <div class="animation h-1 mt-1"></div>
          </h2>
        </div>
      </div>
      <div className="md:flex w-full md:my-8 gap-4">
        <Slider {...gallerySliderSettings}>
          {galleryContentData &&
            galleryContentData.map((item, index) => {
              return (
                <div className="pb-4" key={index}>
                  <GallerySliderCard
                    imageData={item}
                    isFirstCard={index === 0}
                  />
                </div>
              );
            })}
        </Slider>
        {/* <img className="h-72" src={FeaturedImage} alt="FeaturedImage" /> */}
        {/* <div className="mt-8 md:mt-0 p-6 card-background rounded-lg flex flex-col justify-between">
          <div className="">
            <p className="text-xl font-semibold mb-2 text-black">
              Dear members,
            </p>
            <p className="text-lg text-gray-500">
              We are pleased to inform all Youth Alliance Fund members that the
              new President and Secretary posts have been filled :
            </p>

          </div>
          <div className="flex gap-1 items-start">
         
            <p>Manjunath Gudigar President</p>
            <p>Ramanand Gudigar Secretary</p>
           
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurAchievement;
