import {
  Chandan,
  ChiefGuest,
  Circulation,
  FeaturedImage,
  PreAnnualMeet,
  YafNewLogo,
} from "../../core/constants/Images";

const galleryContentData = [
  {
    id: 1,
    date: "12 March 2023",
    title: (
      <p>
        Its Glad to inform all the Youth Alliance Fund members that the new
        <strong className="font-bold text-gray-800"> president</strong> and{" "}
        <strong className="font-bold text-gray-800">secretary</strong> post are
        been elected :
      </p>
    ),
    featuredImage: Circulation,
  },
  {
    id: 2,
    date: "19 March 2023",
    title: (
      <p>
        The Pre Annual Meeting And{" "}
        <strong className="font-bold text-gray-800">
          YAF Application launch
        </strong>{" "}
        program went really well and was a big success, thanks to the
        wholehearted support of our members, showcasing their dedication and
        enthusiasm for our collective goals.
      </p>
    ),
    featuredImage: PreAnnualMeet,
  },
  {
    id: 3,
    date: "19 Sept 2023",
    title: (
      <p>
        The Pre-Annual Meeting was inaugurated by our Chief Guest,
        <strong className="font-bold text-gray-800"> Mr. Umesh Gudigar</strong>,
        a Nuclear Inverter Manufacturer involved in service and distribution in
        Mumbai,
        <strong className="font-bold text-gray-800">
          {" "}
          on 19th September 2023.
        </strong>
      </p>
    ),
    featuredImage: ChiefGuest,
  },
  {
    id: 4,
    date: "19 Sept 2023",
    title: (
      <p>
        A moment of respect to the{" "}
        <strong className="font-bold text-gray-800">
          Developer team Leader
        </strong>{" "}
        who worked hard for the launch of the{" "}
        <strong className="font-bold text-gray-800">YAF Application</strong> by
        the chief guest and our president. Their efforts and expertise played a
        crucial role in bringing our vision to life. We extend our gratitude to
        the entire development team for their collaborative spirit.
      </p>
    ),
    featuredImage: Chandan,
  },
  {
    id: 5,
    date: "1 June 2024",
    title: (
      <>
        <p>
          We’re thrilled to introduce our brand-new logo, a symbol of our
          journey, growth, and future! As of June 1st, 2024, we’re excited to
          share this milestone with you. A huge thank you to our amazing team
          and supporters for being with us every step of the way. Your
          encouragement and feedback have been invaluable, and we’re so proud to
          unveil this new look to you.
        </p>
      </>
    ),
    launchVideo: (
      <div className="h-72 md:h-full">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/E6ZU-_Nei0w?si=o8QR5OZ7KRgp2Tye"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    ),
    // featuredImage: YafNewLogo,
  },
];
export default galleryContentData;
