import React, { useEffect, useState } from "react";
import "./Timeline.css";
import { Chrono } from "react-chrono";
const Timeline = () => {
  const items = [
    {
      title: "May 2019",
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      media: {
        type: "IMAGE",
        source: {
          url: "https://wallpapercave.com/wp/wp2425074.jpg",
        },
      },
    },
    {
      title: "May 2020",
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      media: {
        type: "IMAGE",
        source: {
          url: "https://wallpapercave.com/wp/wp2425074.jpg",
        },
      },
    },
    {
      title: "May 2021",
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      media: {
        type: "IMAGE",
        source: {
          url: "https://wallpapercave.com/wp/wp2425074.jpg",
        },
      },
    },
    {
      title: "May 2022",
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      media: {
        type: "IMAGE",
        source: {
          url: "https://wallpapercave.com/wp/wp2425074.jpg",
        },
      },
    },
    {
      title: "May 2023",
      cardTitle: "Dunkirk",
      url: "http://www.history.com",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      media: {
        type: "IMAGE",
        source: {
          url: "https://wallpapercave.com/wp/wp2425074.jpg",
        },
      },
    },
  ];
  return (
    <>
      <div id="timeline" className="bg-primary-50 rounded-xl pb-8">
        <Chrono
          theme={{
            primary: "#307EF2",
            secondary: "#003C97",
            cardBgColor: "none",
            titleColor: "black",
            titleColorActive: "white",
          }}
          items={items}
          scrollable={{ scrollbar: true }}
          textOverlay
        />
      </div>
      {/* <div style={{ width: '500px', height: '950px' }}>
  <Chrono items={items} mode="VERTICAL" />
</div> */}
    </>
  );
};

export default Timeline;
